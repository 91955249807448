import React, { Component, Fragment } from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Webcam from 'react-webcam';
import BarcodeReader from 'react-barcode-reader';
import ScanningBox from '../ScanningBox';

@inject('LiveDispatchStore')
class Inventory extends Component {
    constructor(props) {
        super(props);
        this.webcamRef = React.createRef();
    }

    handleError = (err) => {};

    handleScanWrapper = (scannedBarcode) => {
        const { LiveDispatchStore, ScanningStore } = this.props;
        const { setItemImage, handledScannedItem, handledScannedBag, handledScannedWarmerItem, displayErrorResultFunc } =
            LiveDispatchStore;
        const { handleScan } = ScanningStore;
        if (this.webcamRef.current) {
            const image = this.webcamRef.current.getScreenshot();
            setItemImage(image);
        }
        let isBagQRCode = scannedBarcode.split('-').length == 1;
        if (isBagQRCode) {
            handledScannedBag(scannedBarcode);
        } else {
            handleScan(scannedBarcode, handledScannedItem, handledScannedWarmerItem, displayErrorResultFunc);
        }
    };

    render() {

        const { LiveDispatchStore } = this.props;

        const {
            itemImage: image,
            itemScanLoading,
            scannedItem,
            scannedItemSKU,
            scannedItemError,
            showScannedItemOnLiveDispatch,
            originalItemImageLink,
        } = LiveDispatchStore;

        return (
            <div className="inventory-menu">
                <div className={`cooking-v2__webcam-area`}>
                    <Webcam
                        audio={false}
                        ref={this.webcamRef}
                        screenshotFormat="image/jpeg"
                        className="webcam"
                        screenshotQuality={1}
                        forceScreenshotSourceSize={true}
                        videoConstraints={{
                            height: 1080,
                            width: 1920,
                        }}
                    />
                </div>
                <Helmet>
                    <title>Inventory</title>
                </Helmet>
                <h1>Inventory Tool</h1>
                <div className="btn-wrapper mm">
                    <Link to="/inventory/live/adjustment">
                        <button className="btn btn-primary width--300px">
                            Current Stock
                        </button>
                    </Link>
                </div>
                <div className="btn-wrapper mm">
                    <Link to="/inventory/adjustment">
                        <button className="btn btn-primary width--300px">
                            Quick Stock Update
                        </button>
                    </Link>
                </div>
                <div className="btn-wrapper mm">
                    <Link to="/inventory/stock-count">
                        <button className="btn btn-primary width--300px">
                            Stock Count
                        </button>
                    </Link>
                </div>
                <div className="btn-wrapper mm">
                    <Link to="/inventory/receive-stock">
                        <button className="btn btn-primary width--300px">
                            Receive New Stock
                        </button>
                    </Link>
                </div>
                <div className="btn-wrapper mm">
                    <Link to="/inventory/add-component-stock">
                        <button className="btn btn-primary width--300px">
                            Add Component Stock
                        </button>
                    </Link>
                </div>

                <BarcodeReader
                    onError={this.handleError}
                    onScan={this.handleScanWrapper}
                />
                <ScanningBox
                    show={showScannedItemOnLiveDispatch}
                    scannedItem={scannedItem}
                    scannedItemSKU={scannedItemSKU}
                    scannedItemError={scannedItemError}
                    handleScan={this.handleScanWrapper}
                    loading={itemScanLoading}
                    originalItemImageLink={originalItemImageLink}
                    itemImage={image}
                    isCurrentPageFullScreen={false}
                />
            </div>
        );
    }
}

export default Inventory;
